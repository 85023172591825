.container {
  position: relative;
  .field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    padding: 5px 10px;
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    border-color: #9e9e9e;
    border-radius: 4px;
    height: 37px;
    outline: none;
    width: 100%;
    cursor: pointer;
    &:is(.disabled) {
      border-width: 0px 0px 1px 0px;
      border-radius: 0px;
      border-style: dashed;
      cursor: default;
      span,
      i {
        color: #9e9e9e;
      }
    }
    i {
      font-size: 24px;
    }
  }
  .wrapper {
    background-color: #ffffff;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    padding: 15px;
    z-index: 1000;
    .month {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 7px;
      b {
        color: #282829;
        font-size: 16px;
      }
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        border-radius: 25%;
        background-color: #ffffff;
        box-shadow: 0 0 4px #00000040;
        cursor: pointer;
        i {
          color: #282829;
          font-size: 20px;
        }
      }
    }
    .weeks {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 7px;
      place-items: center;
      margin: 10px 0px;
      b {
        color: #0000008a;
        font-size: 14px;
      }
    }
    .days {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: auto;
      gap: 7px;
      span {
        display: inline-block;
        width: 100%;
        text-align: center;
        color: #6e6b7b;
        font-size: 14px;
        font-weight: bold;
        padding: 4px 0px;
        cursor: pointer;
        &:is(.active) {
          background-color: var(--primary-color);
          outline: 1px solid var(--primary-color);
          color: white;
          border-radius: 4px;
        }
        &:is(.highlight):not(.active) {
          outline: 1px solid #4185f4;
          color: #4185f4;
          border-radius: 4px;
        }
      }
    }
  }
}
