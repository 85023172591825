.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  gap: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 8px;
  &:hover {
    .drag_icon {
      display: block;
    }
  }
  .wrapper {
    padding: 30px 30px 0px 30px;
  }
  .field_label {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    > div {
      width: calc(100% - 280px);
    }
  }
  .field_label[data-edit] {
    justify-content: space-between;
  }
  .field_description {
    padding: 10px 0px;
  }
  .field {
    margin: 15px 0px 30px 0px;
  }
  .field[data-type="input"],
  .field[data-type="textarea"] {
    max-width: 75%;
  }
  .field[data-type="date"] {
    max-width: 50%;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
    border-top: 1px solid #dadce0;
    padding: 15px 0px;
    font-family: "Poppins", sans-serif;
    .split {
      position: relative;
      width: 30px;
      background-color: #dadce0;
      height: 1px;
      transform: rotate(90deg);
    }
    i {
      color: #606368;
      font-size: 26px;
      cursor: pointer;
    }
  }
  .drag_icon {
    display: none;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    color: #9aa0a6;
    z-index: 2;
    cursor: move;
    i {
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 22px;
      &:last-child {
        top: 6px;
      }
    }
  }
  .highlight {
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: 100%;
    width: 6px;
    border-radius: 6px 0px 0px 6px;
    background-color: #4185f4;
  }
  .indicator {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 10px;
    background-color: var(--primary-color);
    border-radius: 8px 8px 0px 0px;
    z-index: 1;
  }
  .error_msg {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 10px;
    color: #da3025;
    i {
      font-size: 24px;
    }
    span {
      font-size: 14px;
    }
  }
  .asterisk {
    color: #da3025;
  }
  .rules {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-top: 1px solid #dadce0;
    padding: 30px 0px;
    .rule_field {
      display: flex;
      gap: 20px;
      > div {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
      label {
        font-size: 14px;
      }
    }
  }
}

.container[data-error="true"] {
  border: 1px solid #da3025;
}

.option_drop_down {
  min-width: 200px;
}

@media only screen and (max-width: 768px) {
  .container {
    .wrapper {
      padding: 30px 15px 0px 15px;
    }
    .field_label {
      > div {
        width: 100%;
      }
    }
    .field {
      margin: 15px 0px 30px 0px;
    }
    .field[data-type="input"],
    .field[data-type="textarea"],
    .field[data-type="date"] {
      max-width: 100%;
    }
    .footer {
      .split {
        width: 25px;
      }
    }
  }
}
