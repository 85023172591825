.container {
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  background-color: white;
  border-bottom: 1px solid #dadce0;
  display: flex;
  flex-direction: column;
  padding: 15px 15px 0px 15px;
  z-index: 999;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    .title {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;
      svg {
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
      input {
        background-color: white;
        border: none;
        padding: 0px 5px 5px 5px;
        font-size: 20px;
        font-family: "Poppins-Medium";
        max-width: 250px;
        outline: none;
        border-bottom: 1px solid var(--primary-color);
      }
      h1 {
        font-size: 20px;
        font-weight: normal;
        font-family: "Poppins-Medium";
        cursor: pointer;
      }
    }
    .icon {
      display: flex;
      align-items: center;
      gap: 15px;
      i {
        color: #606368;
        font-size: 24px;
        cursor: pointer;
      }
    }
  }
  .bottom {
    display: flex;
    max-width: fit-content;
    margin: 0px auto;
    .tab {
      position: relative;
      color: #3c4043;
      font-size: 16px;
      padding: 10px 15px;
      list-style: none;
      font-family: "Poppins-Medium";
      cursor: pointer;
      &:is(.active) {
        color: var(--primary-color);
      }
      .indicator {
        position: absolute;
        left: 0px;
        bottom: 0px;
        background-color: var(--primary-color);
        width: 100%;
        height: 4px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    }
  }
}

.container101{
  border: 2px solid var(--primary-color);
}

@media only screen and (max-width: 768px) {
  .container {
    .top {
      flex-direction: column;
      .title {
        input {
          max-width: calc(100% - 40px);
        }
      }
      .icon {
        justify-content: flex-end;
        width: 100%;
      }
    }
  }
}

