.bg {
  background-color: var(--bg-color);
  padding-top: var(--top);
  min-height: 100vh;
}
.bg101 {
  background-color: var(--bg-color);
}
.bgborder{
  color: var(--bg-color);
}
.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 768px;
  padding: 15px;
  margin: 0px auto;
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .empty_field {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      border: 1px solid black;
    }
  }
  .cta {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    > div {
      display: flex;
      gap: 15px;
    }
    button {
      padding: 0px 15px;
      height: 36px;
      min-width: 100px;
      color: var(--primary-color);
    }
    .btn_submit {
      background-color: var(--primary-color);
      box-shadow: 0 6px 20px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      border: 1px solid var(--primary-color);
      color: white;
    }
    .btn_navigate {
      box-shadow: 0 6px 20px rgba(0, 0, 0, 0.05);
      background-color: #fff;
      border: 1px solid #dadce0;
      border-radius: 4px;
    }
    .btn_clear {
      background-color: transparent;
      border: none;
    }
  }
}

.footer {
  display: flex;
  justify-content: center;
  margin: 15px 0px;
  span {
    color: #6c6a70;
    font-size: 24px;
  }
}

.popup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 60px 30px;
  h2 {
    display: block;
    text-align: center;
    font-size: 30px;
    font-family: "Poppins-Medium";
    font-weight: normal;
    color: var(--text-color);
  }
  span {
    display: block;
    text-align: center;
    font-size: 20px;
    color: var(--text-color);
  }
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 15px;
  }
}
