.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  .option_field {
    display: flex;
    flex-direction: column;
    gap: 10px;
    > div {
      display: flex;
      align-items: center;
      gap: 15px;
    }
    i {
      &:first-child {
        color: #c5c5c5;
        font-size: 22px;
      }
      &:last-child {
        color: #606368;
        font-size: 26px;
        cursor: pointer;
      }
    }
    input {
      border-width: 0px;
    }
    &:hover {
      input {
        border-width: 0px 0px 1px 0px;
      }
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
    i {
      color: #c5c5c5;
      font-size: 22px;
    }
    .add_option {
      display: flex;
      align-items: center;
      gap: 10px;
      span {
        color: #9e9e9e;
        cursor: pointer;
      }
      .other_option {
        span {
          &:first-child {
            color: #202124;
          }
          &:last-child {
            color: #1a73e8;
            cursor: pointer;
          }
        }
      }
      .error_msg {
        color: red;
        font-size: 14px;
      }
    }
  }
  .select_field {
    max-width: 75%;
  }
  .error_msg {
    color: #da3025;
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    .select_field {
      max-width: 100%;
    }
  }
}
