@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback');

@import 'tailwindcss/base';
@import 'tailwindcss/components';

/* Additional styles */
@import 'additional-styles/utility-patterns.css';
@import 'additional-styles/flatpickr.css';

@import 'tailwindcss/utilities';

/* #root>div:nth-child(1)>div>section>div.bg-white.w-full.md\:max-w-md.lg\:max-w-full.md\:mx-auto.md\:mx-0.md\:w-1\/2.xl\:w-1\/3.h-screen.px-6.lg\:px-16.xl\:px-12.flex.items-center.justify-center>div>form>div:nth-child(1)>input {
    background-color: transparent !important;
    color: transparent !important;
}

#root>div:nth-child(1)>div>section>div.bg-white.w-full.md\:max-w-md.lg\:max-w-full.md\:mx-auto.md\:mx-0.md\:w-1\/2.xl\:w-1\/3.h-screen.px-6.lg\:px-16.xl\:px-12.flex.items-center.justify-center>div>form>div:nth-child(1)>input {
    background-color: transparent !important;
    color: transparent !important;

} */
/* styles.css or in your .css file */
@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
  }
}

@layer components {
    /* .my-react-select-container {
    } */
    .my-react-select-container .my-react-select__control {
      @apply bg-white dark:bg-transparent border-2 border-neutral-300 dark:border-gray-700 hover:border-neutral-400 dark:hover:border-neutral-500 h-[100%] ;
    }
  
    .my-react-select-container .my-react-select__control--is-focused {
      @apply border-neutral-500 hover:border-neutral-500 dark:border-neutral-400 dark:hover:border-neutral-400 shadow-none;
    }
  
    .my-react-select-container .my-react-select__menu {
      @apply bg-neutral-100  dark:bg-neutral-700 border-2 border-neutral-300 dark:border-neutral-600;
    }
  
    .my-react-select-container .my-react-select__option {
      @apply text-neutral-600 dark:text-neutral-200 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-700 dark:hover:bg-neutral-800;
    }
    /* .my-react-select-container .my-react-select__option--is-focused {
      @apply bg-neutral-200 dark:bg-neutral-800;
    } */
  
    .my-react-select-container .my-react-select__indicator-separator {
      @apply bg-neutral-400;
    }
  
    .my-react-select-container .my-react-select__input-container,
    .my-react-select-container .my-react-select__placeholder,
    .my-react-select-container .my-react-select__single-value {
      @apply text-neutral-600 dark:text-neutral-200;
    }
  }

  #root > div.flex.h-screen.overflow-hidden > div.relative.flex.flex-col.flex-1.overflow-y-auto.overflow-x-hidden > main > div > div:nth-child(2) > div > div.dark\:bg-slate-900.FormList_template__aSEwD > div > div.FormList_list__qE5SX > div > img{
    filter: hue-rotate(331deg);
  }
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For Firefox */
.scrollbar-hide {
  scrollbar-width: none;
}
#root > div.flex.h-screen.overflow-hidden > div.relative.flex.flex-col.flex-1.overflow-y-auto.overflow-x-hidden > main > div > div:nth-child(2) > div:nth-child(1) > table{
  position: relative;
    z-index: -999999;
}
