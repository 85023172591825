.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  .color {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .bg {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .code_list {
    display: grid;
    grid-template-columns: repeat(var(--columns), 30px);
    grid-auto-rows: 30px;
    gap: 10px;
    padding: 0px;
    li {
      position: relative;
      border-radius: 50%;
      list-style: none;
      cursor: pointer;
      &:is(.highlight) {
        border: 1px solid #cccccc;
      }
      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 16px;
      }
      i[data-type="color"] {
        color: white;
      }
      i[data-type="bg"] {
        color: #000;
      }
    }
  }
}
