@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("../fonts/poppins/Poppins-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../fonts/poppins/Poppins-Bold.ttf");
  font-display: swap;
}
