
.container {
  .template {
    background-color: #f1f3f4;
    padding: 15px 0px;
    .wrapper {
      width: 80%;
      margin: 0px auto;
      .title {
        margin-bottom: 15px;
        span {
          // color: var(--text-color);
          font-family: "Poppins-Medium", sans-serif;
          font-size: 24px;
        }
      }
      .list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 30px 15px;
        // max-width: 992px;
        margin: 0px auto;
        .card {
          display: flex;
          flex-direction: column;
          gap: 10px;
          cursor: pointer;
          img {
            display: block;
            max-width: 100%;
            height: 130px;
            width: 100%;
            margin: 0px auto;
            border: 1px solid #dfe1e5;
            border-radius: 4px;
            &:hover {
              border-color: var(--primary-color);
            }
          }
          span {
            color: var(--text-color);
            font-family: "Poppins-Medium", sans-serif;
          }
        }
      }
    }
  }
  .form2 {
    // background-color: #606368;
    padding: 15px 0px;
    // height: 100vh;
    .loading {
      display: flex;
      justify-content: center;
      padding-top: 15px;
    }
    .wrapper {
      max-width: 920px;
      margin: 0px auto;
      width: 100%;
      border-collapse: collapse;
      font-family: "Poppins-Medium", sans-serif;
      color: var(--text-color);
      thead {
        th {
          padding: 10px 0px;
          text-align: left;
          color: var(--text-color);
          &:last-child {
            text-align: center;
          }
        }
      }
      span {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      td {
        padding: 7px;
      }
      tbody {
        tr {
          height: 100px;
          &:not([aria-label]) {
            height: auto;
            cursor: pointer;
            td {
              position: relative;
              &:not(:first-child)::after {
                content: "";
                position: absolute;
                left: 0px;
                bottom: 0px;
                width: 100%;
                height: 1px;
                background-color: #e3e3e3;
              }
              &:nth-child(1) {
                border-radius: 8px 0px 0px 8px;
                padding-left: 30px;
                vertical-align: middle;
                svg {
                  width: 30px;
                  height: 30px;
                }
              }
              &:nth-child(2) {
                width: 45%;
              }
              &:nth-child(3) {
                width: 25%;
              }
              &:nth-child(4) {
                width: 25%;
              }
              &:nth-child(5) {
                width: 10%;
                border-radius: 0px 8px 8px 0px;
                > div {
                  display: flex;
                  justify-content: center;
                }
                i {
                  width: 35px;
                  height: 35px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #606368;
                  font-size: 24px;
                  border-radius: 50%;
                  cursor: pointer;
                  &:hover {
                    background-color: #dadce0;
                  }
                }
              }
            }
            // &:hover {
            //   background-color: #f3e8fd;
            //   td {
            //     &::after {
            //       background-color: transparent;
            //     }
            //   }
            // }
          }
        }
      }
    }
  }
  .form {
    background-color: #fff;
    padding: 15px 0px;
    // height: 100vh;
    .loading {
      display: flex;
      justify-content: center;
      padding-top: 15px;
    }
    .wrapper {
      max-width: 920px;
      margin: 0px auto;
      width: 100%;
      border-collapse: collapse;
      font-family: "Poppins-Medium", sans-serif;
      color: var(--text-color);
      thead {
        th {
          padding: 10px 0px;
          text-align: left;
          color: var(--text-color);
          &:last-child {
            text-align: center;
          }
        }
      }
      span {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      td {
        padding: 7px;
      }
      tbody {
        tr {
          height: 100px;
          &:not([aria-label]) {
            height: auto;
            cursor: pointer;
            td {
              position: relative;
              &:not(:first-child)::after {
                content: "";
                position: absolute;
                left: 0px;
                bottom: 0px;
                width: 100%;
                height: 1px;
                background-color: #e3e3e3;
              }
              &:nth-child(1) {
                border-radius: 8px 0px 0px 8px;
                padding-left: 30px;
                vertical-align: middle;
                svg {
                  width: 30px;
                  height: 30px;
                }
              }
              &:nth-child(2) {
                width: 45%;
              }
              &:nth-child(3) {
                width: 25%;
              }
              &:nth-child(4) {
                width: 25%;
              }
              &:nth-child(5) {
                width: 10%;
                border-radius: 0px 8px 8px 0px;
                > div {
                  display: flex;
                  justify-content: center;
                }
                i {
                  width: 35px;
                  height: 35px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #606368;
                  font-size: 24px;
                  border-radius: 50%;
                  cursor: pointer;
                  &:hover {
                    background-color: #dadce0;
                  }
                }
              }
            }
            &:hover {
              background-color: #f3e8fd;
              td {
                &::after {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
}

.dropdown {
  span {
    font-size: 14px !important;
  }
  i {
    font-size: 20px !important;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(00deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
