.section {
  position: relative;
  top: 11px;
  left: 1px;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: var(--primary-color);
  height: 36px;
  width: fit-content;
  border-radius: 8px 8px 0px 0px;
  color: white;
  padding: 0px 15px;
  z-index: 10;
}
.wrapper {
  display: flex;
  flex-direction: column;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 8px;
  .header {
    background-color: var(--primary-color);
    padding: 15px;
    color: white;
    border-radius: 8px 8px 0px 0px;
  }
  .footer {
    padding: 15px;
  }
}
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 8px;
  overflow: hidden;
  padding: 30px 25px 30px 25px;
  .field {
    margin: 15px 0px 30px 0px;
  }
  .highlight {
    position: absolute;
    top: 6px;
    left: 0px;
    height: 100%;
    width: 6px;
    border-radius: 6px 0px 0px 6px;
    background-color: #4185f4;
  }
  .indicator {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 10px;
    background-color: var(--primary-color);
    border-radius: 8px 8px 0px 0px;
    z-index: 1;
  }
}

.container[data-section] {
  .highlight {
    height: 98%;
  }
}
